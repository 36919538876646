import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO
        title="404"
      />
      {/* Page Container */}
      <div className="mt-16 max-w-2xl mx-auto prose">
        <h2>404</h2>
        <div className="mt-16">
        Page not found.
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
